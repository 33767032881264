import { DeleteOutlined, DownOutlined, FormOutlined } from '@ant-design/icons'
import { Col, Dropdown, Empty, Input, Menu, MenuProps, Row, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { Button } from 'ui'

import {
  Attachment,
  Document,
  DocumentType,
} from '../../../api/attachment-standalone/attachment-standalone-client'
import {
  GetAttachmentImage,
  GetImageFromS3,
} from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { asyncForEach, dataURLtoFile } from '../../../utilities/general'
import { ImageDataProps, ImageRules } from '../AttachmentsTypes'
import ImageDateTaken from '../Common/ImageDateTaken'
import { useAttachmentsContext } from '../Context/AttachmentsContext'

import './AttachmentContent.scss'

const { TextArea } = Input

type MenuItem = Required<MenuProps>['items'][number]

type AttachmentContentProps = {
  attachment: Attachment
  imageTypes: DocumentType[]
  imageRules: ImageRules
  narrativeText: string
  setNarrativeText: Dispatch<SetStateAction<string>>
  setImageDataProps: Dispatch<SetStateAction<ImageDataProps>>
  setDisplayEditImage: Dispatch<SetStateAction<boolean>>
  imageData: ImageDataProps[]
  setImageData: Dispatch<SetStateAction<ImageDataProps[]>>
  submitting: boolean
}

const AttachmentContent: FC<AttachmentContentProps> = ({
  attachment,
  imageTypes,
  imageRules,
  narrativeText,
  setNarrativeText,
  setImageDataProps,
  setDisplayEditImage,
  imageData,
  setImageData,
  submitting,
}) => {
  const { documents } = attachment
  const [loading, setLoading] = useState<boolean>(false)

  const { authentication, facilityId } = useAttachmentsContext()

  const orientationItems = [
    {
      key: 'Left',
      label: 'Patient Left',
    },
    {
      key: 'Right',
      label: 'Patient Right',
    },
  ]

  const unsentImageColumns: ColumnsType<ImageDataProps> = [
    {
      dataIndex: 'rowId',
      width: '5%',
      align: 'center',
      render: (value: number, record: ImageDataProps, index: number) => (
        <>{index + 1}</>
      ),
    },
    {
      dataIndex: 'imageDataThumbnail',
      title: 'Image',
      width: '15%',
      align: 'center',
      render: (value: string) => (
        <img
          src={value}
          className='img-preview'
        />
      ),
    },
    {
      dataIndex: 'imageType',
      title: 'Image Type',
      width: '25%',
      render: (value: string, record: ImageDataProps, index: number) => {
        const imgTypes: any = []
        const otherTypes: any = []

        imageTypes.forEach((type) => {
          if (type.documentTypeGroupDescription === '-- X-Ray Types --') {
            imgTypes.push({
              key: `${type.documentTypeId} ${type.documentCode}`,
              label: (
                <a onClick={() => updateImageTypeData(index, type)}>
                  {type.documentDescription}
                </a>
              ),
            })
          } else if (
            type.documentTypeGroupDescription === '-- Non X-Ray Types --'
          ) {
            otherTypes.push({
              key: `${type.documentTypeId} ${type.documentCode}`,
              label: (
                <a onClick={() => updateImageTypeData(index, type)}>
                  {type.documentDescription}
                </a>
              ),
            })
          }
        })

        const items: MenuItem[] = [
          {
            key: 'imageType',
            label: 'Images',
            children: imgTypes,
          },
          {
            key: 'otherType',
            label: 'Other Type',
            children: otherTypes,
          },
        ]

        const imageTypeMenu = (
          <Menu
            className='sa-edit__grid-menu'
            items={items}
          />
        )

        return (
          <Dropdown
            overlay={imageTypeMenu}
            overlayStyle={{ borderRadius: 0 }}
          >
            <a className={`sa-edit__grid-dropdown`}>
              <span>
                {record.imageType?.documentDescription || undefined}&nbsp;
              </span>
              <DownOutlined />
            </a>
          </Dropdown>
        )
      },
    },
    {
      dataIndex: 'orientation',
      title: 'Orientation',
      width: '25%',
      render: (_: string, record: ImageDataProps, index: number) => {
        const orientationDescription = orientationItems.find(
          (item) => item.key === record.orientation,
        )?.label

        const orientationMenu = (
          <Menu
            className='sa-edit__grid-menu'
            items={orientationItems}
            onClick={({ key }) => {
              updateImageData('Orientation', key, index)
            }}
          />
        )

        return (
          record?.imageType?.documentTypeId === 4 && (
            <Dropdown
              overlay={orientationMenu}
              overlayStyle={{ borderRadius: 0 }}
            >
              <a className={`sa-edit__grid-dropdown`}>
                <span>{orientationDescription} &nbsp;</span>
                <DownOutlined />
              </a>
            </Dropdown>
          )
        )
      },
    },
    {
      dataIndex: 'dateTaken',
      title: 'Date Taken',
      width: '25%',
      render: (_: string, record: ImageDataProps, index: number) => (
        <ImageDateTaken
          record={record}
          updateImageData={updateImageData}
          index={index}
          rowId={record.rowId}
        />
      ),
    },
    {
      dataIndex: 'imageData',
      render: (_: string, record: ImageDataProps, index: number) => (
        <div className='sa-edit__grid-actions'>
          <Button
            type='text'
            icon={<FormOutlined />}
            onClick={() => {
              setImageDataProps(record)
              setDisplayEditImage(true)
            }}
          />
          <Button
            type='text'
            icon={<DeleteOutlined />}
            onClick={() => deleteImageRecord(index)}
          />
        </div>
      ),
    },
  ]

  const deleteImageRecord = (index: number) => {
    const imageDataCopy = [...imageData]
    imageDataCopy.splice(index, 1)
    setImageData([...imageDataCopy])
  }

  const updateImageTypeData = (index: number, newValue: DocumentType) => {
    const imageDataCopy = [...imageData]
    imageDataCopy[index].rowKey = new Date().getTime().toString()
    imageDataCopy[index].imageType = newValue
    setImageData(imageDataCopy)
  }

  const updateImageData = (column: string, newValue: string, index: number) => {
    const imageDataCopy = [...imageData]
    switch (column) {
      case 'Orientation':
        localStorage.setItem(
          'standalone-attachment-image-orientation',
          newValue,
        )
        imageDataCopy[index].orientation = newValue
        break
      case 'DateTaken':
        imageDataCopy[index].dateTaken = newValue
        break
      default:
    }
    imageDataCopy[index].rowKey = new Date().getTime().toString()
    setImageData(imageDataCopy)
  }

  useEffect(() => {
    // Making an API call for each document/image tied to the attachment, to retreive the image file path
    ;(async () => {
      const images: ImageDataProps[] = []
      const activeTypes: DocumentType[] = []

      setLoading(true)
      await asyncForEach(documents, async (doc: Document, index: number) => {
        await GetAttachmentImage(
          attachment?.attachmentId,
          doc?.documentId,
          facilityId,
        ).then(async ({ data }) => {
          const imageFileData = await GetImageFromS3(data.documentPath)
          const imageFile = dataURLtoFile(
            'data:image/jpeg;base64,' + imageFileData,
            doc.documentFileName,
          )
          const imageType = imageTypes.find((item) =>
            item.documentCode === doc.documentCode ? item : null,
          )

          images.push({
            rowId: index + 1,
            rowKey: new Date().getTime().toString(),
            imageDataThumbnail: URL.createObjectURL(imageFile),
            imageData: imageFile,
            dateTaken: doc.createDate,
            imageType: imageType,
            orientation: doc.orientationTypeId === 2 ? 'Right' : 'Left', // TODO: this won't work if orientationTypeId is '0'
            document: doc,
            acquisitionType: doc.acquisitionMethod,
          })

          imageType && activeTypes.push(imageType)
        })
      })
        .catch((e) => {})
        .finally(() => {
          setImageData([...images])
          setLoading(false)
        })
    })()
  }, [])

  return (
    <Row>
      <Col flex={1}>
        <Table
          size='middle'
          className='sa-edit__grid'
          columns={unsentImageColumns}
          dataSource={imageData}
          loading={loading || submitting}
          rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
          rowKey='rowKey'
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Capture or upload an attachment image'
              />
            ),
          }}
          scroll={imageData.length > 5 && { y: 450 }}
        />
        <section className='sa-edit__narrative-header'>
          <p className='fs-100'>
            <b>Narrative</b>
          </p>
          <p>{`${narrativeText?.length || 0}/2000 Characters`}</p>
        </section>
        <TextArea
          title='Narrative'
          value={narrativeText}
          maxLength={2000}
          onChange={(e) => setNarrativeText(e.target.value)}
          rows={6}
          disabled={submitting || imageRules?.narrativeDisabled}
        />
      </Col>
    </Row>
  )
}

export default AttachmentContent
