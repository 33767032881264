import { Col, Input, List, Row, Spin, Tabs } from 'antd'
import { useEffect, useState } from 'react'

import api from 'trellis:utilities/api'

import './resource.scss'

import { RoleHelper$ } from 'trellis:utilities/roleHelper'

const { Search } = Input
const { TabPane } = Tabs

const era = 'ERA Enrollment Forms'
const claims = 'Claim Enrollment Forms'
const training = 'Training Videos'
const tips = 'Tips'

export const Resource = () => {
  const [resources, setResources] = useState([])
  const [tabResources, setTabResources] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [tab, setTab] = useState(era)
  const [showLoading, setShowLoading] = useState(true)

  useEffect(() => {
    setShowLoading(true)
    if (resources && resources.length > 0) {
      getTabResources(tab)
    } else {
      getResources()
    }
    setShowLoading(false)
  }, [resources])

  useEffect(() => {
    setShowLoading(true)
    const timeOutId = setTimeout(() => {
      let matches: any
      if (searchValue) {
        matches = resources.filter((resource) => {
          return (
            resource?.displayName
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase()) && resource.group === tab
          )
        })
        setShowLoading(false)
      } else {
        matches = resources.filter((resource) => {
          return resource.group === tab
        })
      }
      setTabResources(matches)
      if (resources.length && matches.length) setShowLoading(false)
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [searchValue])

  const getResources = async () => {
    let { data } = await api.getResources()

    if (!RoleHelper$.isTrellis.peek())
      data = data.filter((resource: any) => resource.version !== 'Vyne Trellis')
    else data = data.filter((resource: any) => resource.version !== 'rPractice')

    setResources(data)
  }

  const getTabResources = (currentTab: any) => {
    const matches: any = resources.filter((resource) => {
      return resource.group === currentTab
    })
    setSearchValue('')
    setTab(currentTab)
    setTabResources(matches)
  }

  const ResourceList = () => {
    if (showLoading)
      return (
        <div className='loading-container'>
          <Spin
            className='loading-spin'
            size='large'
          />
        </div>
      )

    return (
      <List
        className={`resource-list`}
        dataSource={tabResources}
        renderItem={(tabResource) => (
          <List.Item
            onClick={() => window.open(tabResource.downloadUrl, '_blank')}
            className='resource-item pl-1'
          >
            <List.Item.Meta title={<div>{tabResource.displayName}</div>} />
          </List.Item>
        )}
      />
    )
  }

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <Row>
        <Col flex={1}>
          <span className='page-content-header__title'>Resources</span>
        </Col>
        <Col className='mr-100'>
          <Search
            placeholder='Search'
            value={searchValue}
            allowClear
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </Col>
      </Row>
      <Tabs
        className='pt-100'
        onChange={getTabResources}
      >
        <TabPane
          key={era}
          tab={era}
        >
          <p className='resource-text'>
            To receive Electronic Remittance Advice (ERAs) through Vyne Dental
            you must first complete enrollment. Please follow the instructions
            for each form.
          </p>
          <ResourceList />
        </TabPane>
        <TabPane
          key={claims}
          tab={claims}
        >
          <p className='resource-text'>
            Prior to submitting claims electronically to the following carriers
            you must first complete enrollment. Please follow the instructions
            on the cover sheet for each form.
          </p>
          <ResourceList />
        </TabPane>
        <TabPane
          key={training}
          tab={training}
        >
          <ResourceList />
        </TabPane>
        <TabPane
          key={tips}
          tab={tips}
        >
          <ResourceList />
        </TabPane>
      </Tabs>
    </article>
  )
}
