import { CameraOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Button } from 'ui'

import {
  ScreenCaptureGetImage,
  ScreenCaptureStoragePath,
} from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { dataURLtoFile, ErrorPNotify } from '../../../utilities/general'
import {
  PNotifyText,
  ScreenCaptureInstallUrl,
} from '../../attachment/constants'
import { ImageDataProps } from '../AttachmentsTypes'

import './ScreenCapture.scss'

import { useAttachmentsContext } from '../Context/AttachmentsContext'

type ScreenCaptureProps = {
  setImageDataProps: Dispatch<SetStateAction<ImageDataProps>>
  imageData: ImageDataProps[]
  setImageData: Dispatch<SetStateAction<ImageDataProps[]>>
}

const ScreenCapture: FC<ScreenCaptureProps> = ({
  setImageDataProps,
  imageData,
  setImageData,
}) => {
  const [visibleUseScreenCaptureDialog, setVisibleUseScreenCaptureDialog] =
    useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)
  const isPolling = useRef<boolean>(false)
  const isMounted = useRef<boolean>(true)

  const defaultOrientation =
    localStorage.getItem('standalone-attachment-image-orientation') || 'Right'
  const { authentication } = useAttachmentsContext()

  useEffect(() => {
    return () => {
      isMounted.current = false
      isPolling.current = false
    }
  }, [])

  const getStoragePath = useCallback(
    async (imageDataCopy: ImageDataProps[]) => {
      if (isSending) return
      setIsSending(true)

      await ScreenCaptureStoragePath(
        parseInt(authentication.CustomerId),
      )
        .then((response) => {
          const storagePathResponse = response.data
          isPolling.current = true
          startFilePolling(
            storagePathResponse.documentName,
            new Date(storagePathResponse.expirationTime),
            imageDataCopy,
          )
          window.location.href = storagePathResponse.documentPath
        })
        .catch((error) => {
          ErrorPNotify(PNotifyText.screenCaptureError)
        })
      if (isMounted.current) {
        setIsSending(false)
      }
    },
    [isSending],
  )

  const getImage = useCallback(
    async (fileName: string, imageData: ImageDataProps[]) => {
      await ScreenCaptureGetImage(
        fileName,
        parseInt(authentication.CustomerId),
      )
        .then((response) => {
          if (response.data) {
            isPolling.current = false
            setVisibleUseScreenCaptureDialog(false)
            const file = dataURLtoFile(
              'data:image/jpeg;base64,' + response.data,
              fileName + '.jpg',
            )
            const acquisitionType = 'Screen-Trellis-SA'
            const rowId = imageData.length + 1
            const captureDate = new Date()
            const rowKey = captureDate.getTime().toString()
            const dateTaken = captureDate.toString()

            setImageDataProps({
              imageData: file,
              orientation: defaultOrientation,
              imageDataThumbnail: URL.createObjectURL(file),
              acquisitionType,
              rowKey,
              rowId,
              imageChanged: true,
              dateTaken: dateTaken,
            })

            setImageData([
              ...imageData,
              {
                imageData: file,
                orientation: defaultOrientation,
                imageDataThumbnail: URL.createObjectURL(file),
                acquisitionType,
                rowKey,
                rowId,
                imageChanged: true,
                dateTaken: dateTaken,
              },
            ])
          }
        })
        .catch((error) => {})
    },
    [visibleUseScreenCaptureDialog],
  )

  // the user must close the overlay to stop screen capture polling
  // we dont have a way to know if the screen capture app is running
  const startFilePolling = (
    fileName: string,
    expireTime: Date,
    imageDataCopy: ImageDataProps[],
  ) => {
    const currentTime = new Date().getTime()
    const expiredTime = expireTime.getTime()
    const defaultExpireTime = 270000 //set to four and a half minutes
    const timeToExpire =
      expiredTime - currentTime > 0
        ? expiredTime - currentTime
        : defaultExpireTime

    const pollingIntervalId = setInterval(() => {
      if (!isPolling.current) {
        clearInterval(pollingIntervalId)
      }
      getImage(fileName, imageDataCopy)
    }, 1000)
    setTimeout(clearInterval, timeToExpire, pollingIntervalId)
  }

  return (
    <>
      <Button
        type='primary'
        label='Screen Capture'
        icon={<CameraOutlined />}
        onClick={() => {
          setVisibleUseScreenCaptureDialog(!visibleUseScreenCaptureDialog)
          getStoragePath(imageData)
        }}
      />
      <Modal
        className='sa-screen-capture-modal'
        title={<p className='sa-screen-capture-modal__title'>Screen Capture</p>}
        visible={visibleUseScreenCaptureDialog}
        destroyOnClose
        onCancel={() =>
          setVisibleUseScreenCaptureDialog(!visibleUseScreenCaptureDialog)
        }
        afterClose={() => {
          isPolling.current = false
        }}
        footer={null}
      >
        <div className='sa-screen-capture-modal__acquisition-install sa-screen-capture-modal__acquisition-install--link'>
          If the capture tool doesn't open,
          <br />
          click&nbsp;
          <a
            onClick={() => {
              isPolling.current = false
              setVisibleUseScreenCaptureDialog(!visibleUseScreenCaptureDialog)
            }}
            href={ScreenCaptureInstallUrl}
          >
            here
          </a>
          &nbsp;to install.
        </div>
        <div className='sa-screen-capture-modal__acquisition-note'>
          *Keep this window open when using the capture tool.
        </div>
      </Modal>
    </>
  )
}

export default ScreenCapture
