import { Button, Col, Form, Modal, Row } from 'antd'
import React, { FC, useEffect, useState } from 'react'

import { GlobalState, LDFlags$ } from 'trellis:state/globalState'

import { FormInput } from '../../../../../_siteWide/form/formInput'
import { FormMaskInput } from '../../../../../_siteWide/form/formMaskInput'
import { FormTextArea } from '../../../../../_siteWide/form/formTextArea'
import { validateRequiredFields } from '../../../../../_siteWide/form/util'
import api from '../../../../../../utilities/api'
import { showMessage } from '../../../../../../utilities/general'
import { validatePhone } from '../../../../../../utilities/validators/phoneValidator'
import ContactUs, { ContactUsProps } from './ContactUs.tsx'
import {
  CONTACT_DENTAL_INTEL,
  CONTACT_PATTERSON,
  CONTACT_VYNE_DENTAL,
} from './ContactUsConstants'
import { observer } from '@legendapp/state/react'

interface ShowModal {
  isVisible: boolean
  setIsVisible: (value: boolean) => void
}

const defaultContactSupport: any = {
  ContactName: '',
  Phone: '',
  Message: '',
  errors: [],
}

const ContactSupport: FC<ShowModal> = observer(({ isVisible, setIsVisible }) => {
  const [contact, setContact] = useState({ ...defaultContactSupport })
  const [savingContact, setSavingContact] = useState(false)

  const [supportForm] = Form.useForm()
  const [contactUsProps, setContactUsProps] = useState<ContactUsProps>()

  useEffect(() => {
    getContactUsProps() // hook to get <ContactUs> props
  }, [GlobalState.ActiveServices])

  const submitSupportMessage = () => {
    const copy = { ...contact }
    copy.SupportEmailAddress = contactUsProps?.email

    validateRequiredFields(contact, [])
    validatePhone(copy.Phone)

    if (!contact.errors.length) {
      setSavingContact(true)
      copy.Subject = copy.Phone
      api
        .contactSupport(copy, GlobalState.Auth.AccessToken.peek())
        .then(() => {
          showMessage(
            'Thank you for contacting support. Someone will follow up with you soon.',
            'success',
          )
          resetContactSupport()
          setIsVisible(false)
          setSavingContact(false)
        })
        .catch(() => {
          showMessage(
            'There was an error submitting a support message. Please try again later.',
          )
          setSavingContact(false)
        })
    }

    setContact(copy)
  }

  const resetContactSupport = () => {
    const copy = { ...defaultContactSupport }
    copy.errors = []
    setContact(copy)
  }

  const closeSupportModal = () => {
    resetContactSupport()
    window.setTimeout(() => setIsVisible(false), 100)
  }

  const getContactUsProps = () => {
    if (GlobalState.ActiveServices.DENTAL_INTEL.get()) {
      setContactUsProps(CONTACT_DENTAL_INTEL)
    } else if (GlobalState.ActiveServices.EAGLESOFT.get()) {
      setContactUsProps(CONTACT_PATTERSON)
    } else {
      setContactUsProps(CONTACT_VYNE_DENTAL)
    }
  }

  return (
    <Modal
      title={<b className='fs-150'>Contact Support</b>}
      footer={[
        <Button
          key='cancel'
          onClick={closeSupportModal}
        >
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={savingContact}
          onClick={() => submitSupportMessage()}
        >
          Submit
        </Button>,
      ]}
      visible={isVisible}
      onCancel={closeSupportModal}
    >
      <>
        <Row className='pt-015'>
          <b>Send us a message</b>
        </Row>
        <Form
          form={supportForm}
          className='pt-025'
        >
          <Row className='pt-025'>
            <Col span={24}>
              <p>Name</p>
              <FormInput
                name='ContactName'
                element={contact}
                setElement={setContact}
                initialValue={contact.ContactName}
                required
              />
              <p>Phone</p>
              <FormMaskInput
                element={contact}
                format='(###)###-####'
                initialValue={contact.Phone}
                mask={'_'}
                name='Phone'
                required
                setElement={setContact}
                validator={validatePhone}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p>Message</p>
              <FormTextArea
                name='Message'
                label='Enter your message'
                element={contact}
                setElement={setContact}
                initialValue={contact.Message}
                className='no-resize'
                required
              />
            </Col>
          </Row>
        </Form>
      </>
      <ContactUs {...contactUsProps} />
    </Modal>
  )
})

export default ContactSupport
