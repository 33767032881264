import {
  endOfDay,
  format,
  intervalToDuration,
  startOfDay,
  subDays,
} from 'date-fns'
import { FC, useEffect, useState } from 'react'

import { SearchStates } from 'ui'

import { DateRange } from 'ui'
import {
  AttachmentListItem,
  SentAttachmentListItem,
  SentAttachmentsRequest,
} from '../../api/attachment-standalone/attachment-standalone-client'
import {
  GetAttachmentList,
  GetSentAttachmentList,
} from '../../api/attachment-standalone/attachmentStandaloneApi'
import { useFuse } from '../../utilities/fuzzySearch/useFuse'
import { showMessage } from '../../utilities/general'
import AttachmentsGrid from './AttachmentsGrid/AttachmentsGrid'
import AttachmentsHeader from './AttachmentsHeader/AttachmentsHeader'
import { AttachmentStatusFilters } from './AttachmentsTypes'
import AttachmentView from './AttachmentView/AttachmentView'
import AttachmentsContextProvider, {
  useAttachmentsContext,
} from './Context/AttachmentsContext'
import SentAttachmentView from './SentAttachmentView/SentAttachmentView'

import './Attachments.scss'

const Attachments: FC = () => {
  const [filters, setFilters] = useState<AttachmentStatusFilters>({
    currentPage: 1,
    pageSize: 10,
    status: '',
  })
  const [unsentSearchInput, setUnsentSearchInput] = useState<SearchStates>('')
  const [sentSearchInput, setSentSearchInput] = useState<SearchStates>('')
  const [dateRange, setDateRange] = useState<{
    dates: [Date, Date]
    numberOfDays: number
  }>({
    dates: [startOfDay(subDays(new Date(), 14)), endOfDay(new Date())],
    numberOfDays: 14,
  })

  const {
    activeTab,
    attachmentId,
    attachments,
    authentication,
    facilityId,
    sentAttachmentId,
    sentAttachments,
    setAttachments,
    setGridLoading,
    setSentAttachmentId,
    setSentAttachments,
    getSortedAttachments,
  } = useAttachmentsContext()

  useEffect(() => {
    const loadGrid = async () => {
      if (authentication?.AccessToken && facilityId && activeTab === 'Unsent') {
        await GetAttachmentList(facilityId)
          .then(({ data }) => {
            setAttachments(data)
            setGridLoading(false)
          })
          .catch(() => {
            setGridLoading(false)
            showMessage('Failed while retrieving the list of attachments.')
          })
      }
    }
    loadGrid()
  }, [authentication?.AccessToken, facilityId])

  useEffect(() => {
    ;(async () => {
      if (attachmentId === null && attachments.length > 0) {
        // Refresh attachment list after save/hold/send/cancel
        setGridLoading(true)
        GetAttachmentList(facilityId)
          .then(({ data }) => {
            setAttachments(data)
          })
          .catch(() => {
            showMessage(
              'Something went wrong while loading the list of attachments.',
            )
          })
          .finally(() => {
            setGridLoading(false)
          })
      }
    })()
  }, [attachmentId])

  useEffect(() => {
    onSearchUnsent(unsentSearchInput as string)
  }, [unsentSearchInput])

  useEffect(() => {
    onSearchSent(sentSearchInput as string)
  }, [sentSearchInput])

  const filterUnsentListCallBack = (data: AttachmentListItem[]) => {
    let list: AttachmentListItem[] = JSON.parse(JSON.stringify(data))
    if (filters.status)
      list = list.filter(
        (attachment) => attachment.attachmentStatus === filters.status,
      )
    return list
  }

  const filterSentListCallBack = (data: SentAttachmentListItem[]) => {
    const list: SentAttachmentListItem[] = JSON.parse(JSON.stringify(data))
    return list
  }

  // Custom hook used to filter and search for unsent attachments
  const { hitItems: unsentHitItems, onSearch: onSearchUnsent } = useFuse<any>({
    data: attachments,
    filterCallBack: filterUnsentListCallBack,
    matchAllOnEmptyQuery: true,
    options: {
      keys: [
        'datesOfService',
        'patientFirstName',
        'patientLastName',
        'recipientName',
        'attachmentStatus',
      ],
    },
  })

  // Custom hook used to filter and search for sent attachments
  const { hitItems: sentHitItems, onSearch: onSearchSent } = useFuse<any>({
    data: sentAttachments,
    filterCallBack: filterSentListCallBack,
    matchAllOnEmptyQuery: true,
    options: { keys: ['patientName', 'recipientName', 'hmfId'] },
  })

  // Trigger default search for dates when switching to Sent tab for the first time, or when grid was previously empty
  const handleTabChange = async () => {
    const request: SentAttachmentsRequest = {
      sentAfter: format(dateRange.dates[0], 'yyyy-MM-dd'),
      sentBefore: format(dateRange.dates[1], 'yyyy-MM-dd'),
    }

    if (activeTab === 'Unsent' && sentAttachments.length === 0) {
      setGridLoading(true)
      await GetSentAttachmentList(facilityId, request)
        .then(({ data }) => {
          setSentAttachments(data)
        })
        .finally(() => setGridLoading(false))
    } else if (activeTab === 'Unsent') {
      const sortedAttachments = getSortedAttachments(
        'sent',
        sentAttachments,
      ) as SentAttachmentListItem[]
      setSentAttachments(sortedAttachments)
    } else if (activeTab === 'Sent') {
      const sortedAttachments = getSortedAttachments(
        'unsent',
        attachments,
      ) as AttachmentListItem[]
      setAttachments(sortedAttachments)
    }
  }

  // Handle search by date range for Sent attachments when triggered manually
  const handleSentAttachmentSearch = async (date: DateRange['dates']) => {
    const durationAsDays: number = intervalToDuration({
      start: date[0],
      end: date[1],
    }).days
    setDateRange({
      dates: [startOfDay(date[0]), endOfDay(date[1])],
      numberOfDays: durationAsDays,
    })

    const request: SentAttachmentsRequest = {
      sentAfter: format(date[0], 'yyyy-MM-dd'),
      sentBefore: format(date[1], 'yyyy-MM-dd'),
    }

    setGridLoading(true)

    await GetSentAttachmentList(facilityId, request)
      .then(({ data }) => {
        setSentAttachments(data)
        setGridLoading(false)
      })
      .catch((e) => {
        setGridLoading(false)
      })
  }

  return (
    <article className='page-content__container'>
      <div className='sa-container'>
        {sentAttachmentId && (
          <SentAttachmentView
            authentication={authentication}
            sentAttachmentId={sentAttachmentId}
            setSentAttachmentId={setSentAttachmentId}
          />
        )}
        {!(sentAttachmentId || attachmentId) && (
          <>
            <AttachmentsHeader
              dateRange={dateRange}
              handleSearch={handleSentAttachmentSearch}
              readyToSendTotal={
                unsentHitItems.filter(
                  (item) => item.attachmentStatus === 'Ready to Send',
                ).length
              }
              searchInput={sentSearchInput}
              setDateRange={setDateRange}
              setSearchInput={setSentSearchInput}
              unsentAttachments={unsentHitItems
                .filter((item) => item.attachmentStatus === 'Ready to Send')
                .map((item) => item.attachmentId)}
            />
            <AttachmentsGrid
              data={activeTab === 'Unsent' ? unsentHitItems : sentHitItems}
              filters={filters}
              handleTabChange={handleTabChange}
              searchInput={unsentSearchInput}
              sentDates={dateRange.dates}
              setFilters={setFilters}
              setSearchInput={setUnsentSearchInput}
            />
          </>
        )}
        {attachmentId && <AttachmentView />}
      </div>
    </article>
  )
}

export default () => (
  <AttachmentsContextProvider>
    <Attachments />
  </AttachmentsContextProvider>
)
