// packages
import {
  CloudDownloadOutlined,
  CustomerServiceOutlined,
  DollarOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MailOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  WarningFilled,
} from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { differenceInMinutes, isToday } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { LogError } from 'utils'

// components
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

// utils
import api from '../../../../../utilities/api'
// state
import { usePostAuthLayoutContext } from '../../context/PostAuthLayoutContext'
import ContactSupport from './modals/ContactSupport'

// assets
import DownChevron from 'trellis:assets/down-chevron.svg?react'
import pendoIcon from 'trellis:assets/pendo-icon.png'
import rPracticeLogo from 'trellis:assets/rpractice.png'
import vyneTrellisLogo from 'trellis:assets/vyne-trellis-logo.png'

import './Header.scss'

import { RoleHelper$ } from 'trellis:utilities/roleHelper'
import { observer } from '@legendapp/state/react'

// active services used in Header
export interface HeaderActiveServices {
  DENTAL_INTEL?: boolean
  TRELLIS_CLAIMS?: boolean
  EAGLESOFT?: boolean
  REMOTE_LITE?: boolean
}
export interface HeaderRules {
  headerClassModifier?: string
  showHeaderLogo?: boolean
  showRPracticeLogo?: boolean
  showTrellisLogo?: boolean
  showRemoteSupport?: boolean
}

export const getHeaderRules = (activeServices: HeaderActiveServices) => {
  const rules: HeaderRules = {
    headerClassModifier:
      activeServices.REMOTE_LITE && !activeServices.TRELLIS_CLAIMS
        ? 'header--rpractice'
        : 'header--trellis',
    showHeaderLogo: !activeServices.DENTAL_INTEL,
    showRPracticeLogo:
      activeServices.REMOTE_LITE && !activeServices.TRELLIS_CLAIMS,
    showTrellisLogo:
      !activeServices.DENTAL_INTEL && activeServices.TRELLIS_CLAIMS,
    // TODO: add anymore rules if needed
    showRemoteSupport: !activeServices.EAGLESOFT,
  }

  return rules
}

const Header: FC = observer(() => {
  // global state
  const activeServices = GlobalState.ActiveServices.get()
  const flags = LDFlags$.get()
  const practiceDetails = GlobalState.PracticeInfo.get()
  const userInfo = GlobalState.UserInfo.get()
  // context state
  const { setTotalUnreadMessages, totalUnreadMessages } =
    usePostAuthLayoutContext()
  // local state
  const [showingContactSupport, setShowingContactSupport] =
    useState<boolean>(false)
  const [PmsSyncExpired, setPmsSyncExpired] = useState<boolean>(false)

  const rules = getHeaderRules(activeServices)

  const getProfileName = (): string => {
    let name: string = userInfo.userName ? userInfo.userName : 'User Profile'
    if (userInfo?.firstName && userInfo?.lastName)
      name = `${userInfo.firstName} ${userInfo.lastName}`
    else if (userInfo?.userName) name = userInfo.userName

    return name
  }

  const profileName = getProfileName()

  useEffect(() => {
    if (location.pathname !== '/Messages') handleGetMessages()
    if (flags.vsPmsSyncStatus && flags.operaVynesyncAutomatedeligibility)
      checkPmsSyncStatus()

    const pendoImageBadge = document.querySelector('[id^="pendo-image-badge-"]')
    if (pendoImageBadge) pendoImageBadge.setAttribute('src', pendoIcon)
  }, [])

  const handleGetMessages = async () => {
    const storedTotalUnreadMessages: number = parseInt(
      JSON.parse(localStorage.getItem('trellis-total-unread-messages')),
    )
    if (storedTotalUnreadMessages >= 0)
      setTotalUnreadMessages(storedTotalUnreadMessages)

    const lastMessagesCallTime: string = localStorage.getItem(
      'trellis-last-messages-call-time',
    )

    let timeLapse: number = 0
    if (lastMessagesCallTime) {
      timeLapse = differenceInMinutes(
        new Date(),
        new Date(lastMessagesCallTime),
      )
    }

    if (!lastMessagesCallTime || timeLapse >= 30) getMessages()
  }

  const checkPmsSyncStatus = async () => {
    const pmsSyncStatusInfo = localStorage.getItem('Pms-Sync-Status-Info')

    if (pmsSyncStatusInfo) {
      const { dateChecked, pmsSyncIsExpired } = JSON.parse(pmsSyncStatusInfo)

      if (isToday(new Date(dateChecked))) {
        setPmsSyncExpired(pmsSyncIsExpired)
        return
      }
    }

    getPmsSyncStatusFromApi()
  }

  const getPmsSyncStatusFromApi = async () => {
    await api
      .getPmsSyncStatus()
      .then(({ data }) => {
        const pmsSyncStatusInfo = JSON.stringify({
          dateChecked: new Date(),
          pmsSyncIsExpired: data.pmsSyncIsExpired,
        })

        localStorage.setItem('Pms-Sync-Status-Info', pmsSyncStatusInfo)
        setPmsSyncExpired(data.pmsSyncIsExpired)
      })
      .catch(() => {
        const pmsSyncStatusInfo = JSON.stringify({
          dateChecked: new Date(),
          pmsSyncIsExpired: false,
        })

        localStorage.setItem('Pms-Sync-Status-Info', pmsSyncStatusInfo)
      })
  }

  const getMessages = async () => {
    const getMessagesRequest = {
      startDate: new Date(),
      endDate: new Date(),
      range: 10,
      offset: 0,
      sortColumn: 'MessageDate',
      sortDirection: 'desc',
      exactFilters: {},
      searchFilters: {},
      searchText: '',
    }

    try {
      const { data } = await api.getMessages(getMessagesRequest)

      localStorage.setItem(
        'trellis-last-messages-call-time',
        new Date().toString(),
      )
      localStorage.setItem(
        'trellis-total-unread-messages',
        data.totalUnreadMessages,
      )
      setTotalUnreadMessages(data.totalUnreadMessages)
    } catch (err) {
      LogError(err, 'Failed to get messages')
    }
  }

  const userProfileMenu = (
    <Menu className='user-profile-menu'>
      {practiceDetails?.facilityID && (
        <p className='user-profile-menu__non-item-text user-profile-menu__non-item-text--facility'>
          Facility ID: {practiceDetails.facilityID}
        </p>
      )}
      {
        <>
          <Menu.Item key='myPractice'>
            <NavLink
              className='user-profile-menu__item'
              to='/Practice/PracticeInfo'
            >
              <ShopOutlined className='user-profile-menu__item-icon mr-075' />
              <p className='user-profile-menu__item-label'>My Practice</p>
            </NavLink>
          </Menu.Item>
          <Menu.Item key='downloads'>
            <NavLink
              className='user-profile-menu__item'
              to='/Practice/Downloads'
            >
              <CloudDownloadOutlined className='user-profile-menu__item-icon mr-075' />
              <p className='user-profile-menu__item-label'>Downloads</p>
            </NavLink>
          </Menu.Item>
          {RoleHelper$.isTrellis.get() && !RoleHelper$.isDentalIntel.get() && (
            <Menu.Item key='billingInfo'>
              <NavLink
                className='user-profile-menu__item'
                to='/Practice/BillingDetails'
              >
                <DollarOutlined className='user-profile-menu__item-icon mr-075' />
                <p className='user-profile-menu__item-label'>Billing Info</p>
              </NavLink>
            </Menu.Item>
          )}
          <Menu.Item key='userManagement'>
            <NavLink
              className='user-profile-menu__item'
              to='/Practice/UserManagementSso'
            >
              <TeamOutlined className='user-profile-menu__item-icon mr-075' />
              <p className='user-profile-menu__item-label'>User Management</p>
            </NavLink>
          </Menu.Item>
        </>
      }
      <Menu.Item key='support'>
        <a
          className='user-profile-menu__item'
          onClick={() => setShowingContactSupport(true)}
        >
          <CustomerServiceOutlined className='user-profile-menu__item-icon mr-075' />
          <p className='user-profile-menu__item-label'>Contact Support</p>
        </a>
      </Menu.Item>
      {rules.showRemoteSupport && (
        <Menu.Item key='webex'>
          <NavLink
            className='user-profile-menu__item'
            to='https://vynedental.screenconnect.com/'
            target='_blank'
            rel='noreferrer'
          >
            <GlobalOutlined className='user-profile-menu__item-icon mr-075' />
            <div className='user-profile-menu__item-label'>Remote Support</div>
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item key='resources'>
        <NavLink
          className='user-profile-menu__item'
          to='/Resource/Index'
        >
          <InfoCircleOutlined className='user-profile-menu__item-icon mr-075' />
          <div className='user-profile-menu__item-label'>Resources</div>
        </NavLink>
      </Menu.Item>
      <Menu.Item key='logout'>
        <a
          className='user-profile-menu__item'
          onClick={() => GlobalState.IsAuthenticated.set(false)}
        >
          <LogoutOutlined className='user-profile-menu__item-icon mr-075' />
          <p className='user-profile-menu__item-label'>Log Out</p>
        </a>
      </Menu.Item>
      <p className='user-profile-menu__non-item-text user-profile-menu__non-item-text--copyright'>
        &#169; {new Date().getFullYear()} Napa EA/MEDX, LLC.
      </p>
    </Menu>
  )

  return (
    <>
      <ContactSupport
        isVisible={showingContactSupport}
        setIsVisible={setShowingContactSupport}
      />
      <header className={`header ${rules.headerClassModifier}`}>
        {rules.showHeaderLogo && (
          <>
            {rules.showRPracticeLogo && !rules.showTrellisLogo ? (
              <img
                alt='Vyne Dental RPractice'
                className='header__logo'
                data-testid='rpractice-logo'
                src={rPracticeLogo}
              />
            ) : (
              <img
                alt='Vyne Dental Trellis'
                className='header__logo'
                data-testid='trellis-logo'
                src={vyneTrellisLogo}
              />
            )}
          </>
        )}
        {practiceDetails && userInfo && (
          <section className='header__controls-container'>
            {flags.vsPmsSyncStatus &&
              flags.operaVynesyncAutomatedeligibility &&
              PmsSyncExpired === true && (
                <section
                  aria-label='Pms Sync Status'
                  className='header__control-item mr-125 pms-sync-status'
                >
                  <WarningFilled className='header__control-item-icon' />
                  <p className='header__control-item-text ml-050 mr-025'>
                    PMS Sync: Offline
                  </p>
                </section>
              )}
            <section
              aria-label='messages'
              className='header__control-item mr-200'
            >
              <NavLink
                className='messages-icon'
                to='/Messages'
              >
                <MailOutlined className='header__control-item-icon' />
                {totalUnreadMessages > 0 && (
                  <div className='messages-icon__unread-notification'></div>
                )}
              </NavLink>
            </section>
            <section className='header__control-item mr-125'>
              <ShopOutlined className='header__control-item-icon' />
              <p className='header__control-item-text ml-025'>
                {practiceDetails?.officeName}
              </p>
            </section>
            <Dropdown
              overlay={userProfileMenu}
              trigger={['click']}
            >
              <section
                aria-label='user profile'
                className='header__control-item'
              >
                <UserOutlined className='header__control-item-icon' />
                <p className='header__control-item-text ml-025 mr-025'>
                  {profileName}
                </p>
                <DownChevron className='header__control-item-icon' />
              </section>
            </Dropdown>
          </section>
        )}
      </header>
    </>
  )
})

export default Header
