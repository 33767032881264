import { Drawer, Table, Tabs } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/lib/table/interface'
import {
  Dispatch,
  FC,
  Key,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useReactToPrint } from 'react-to-print'

import { SearchStates } from 'ui'
import { ExportTableAsCSV } from 'utils'

import {
  AttachmentListItem,
  SentAttachmentListItem,
} from '../../../api/attachment-standalone/attachment-standalone-client'
import { AttachmentStatusFilters } from '../AttachmentsTypes'
import { useAttachmentsContext } from '../Context/AttachmentsContext'
import {
  deleteSelectedAttachments,
  holdSelectedAttachments,
  refreshSentAttachmentGrid,
  refreshUnsentAttachmentGrid,
  sendSelectedAttachments,
} from './_attachmentGridServices'
import AttachmentsGridToolbar from './AttachmentsGridToolbar/AttachmentsGridToolbar'
import { ColumnSelection } from './ColumnSelection/ColumnSelection'

import './AttachmentsGrid.scss'

const { TabPane } = Tabs

type AttachmentsGridProps = {
  data: {
    [key in keyof AttachmentListItem | keyof SentAttachmentListItem]:
      | string
      | number
      | boolean
  }[]
  filters: AttachmentStatusFilters
  handleTabChange: () => Promise<void>
  searchInput: SearchStates
  sentDates: [Date, Date]
  setFilters: Dispatch<SetStateAction<AttachmentStatusFilters>>
  setSearchInput: Dispatch<SetStateAction<SearchStates>>
}

type AttachmentBulkAction = 'Send' | 'Hold' | 'Delete'

const AttachmentsGrid: FC<AttachmentsGridProps> = ({
  data,
  filters,
  handleTabChange,
  searchInput,
  sentDates,
  setFilters,
  setSearchInput,
}) => {
  const {
    activeTab,
    authentication,
    facilityId,
    getGridColumns,
    getStorageOrDefaultColumns,
    gridLoading,
    printingAttachment,
    setActiveTab,
    setAttachments,
    setGridLoading,
    setSentAttachments,
    setShowingSettings,
    setVisibleColumns,
    showingSettings,
    setUnsentSortColumn,
    setUnsentSortOrder,
    setSentSortColumn,
    setSentSortOrder,
    getSortedAttachments,
  } = useAttachmentsContext()

  const [selectedAttachments, setSelectedAttachments] = useState<
    AttachmentListItem[]
  >([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const unsentTableRef = useRef<HTMLDivElement>(null)
  const sentTableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setVisibleColumns(getStorageOrDefaultColumns())
  }, [activeTab])

  const updatePaging = (CurrentPage: number, PageSize: number) => {
    const filtersCopy = { ...filters }

    filtersCopy.currentPage = CurrentPage
    filtersCopy.pageSize = PageSize

    setFilters({ ...filtersCopy })
  }

  const pagination = {
    showSizeChanger: true,
    current: filters.currentPage,
    pageSize: filters.pageSize,
    total: data?.length,
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page: number, pageSize: number) => updatePaging(page, pageSize),
  }

  const onTabChange = (key: string) => {
    handleTabChange()
    setActiveTab(key)
  }

  const onSelectChange = (
    selectedRowKeys: React.Key[],
    selectedRows: AttachmentListItem[],
  ) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedAttachments(selectedRows)
  }

  const onSelectBulkAction = async (action: AttachmentBulkAction) => {
    setGridLoading(true)

    const selectedAttachmentIds =
      action === 'Send'
        ? selectedAttachments
            .filter(
              (attachment) => attachment.attachmentStatus === 'Ready to Send',
            )
            .map((attachment) => attachment.attachmentId)
        : selectedAttachments.map((attachment) => attachment.attachmentId)

    switch (action) {
      case 'Send':
        await sendSelectedAttachments(selectedAttachmentIds)
        bulkActionCleanup('Send')
        break
      case 'Hold':
        await holdSelectedAttachments(selectedAttachmentIds)
        bulkActionCleanup('Hold')
        break
      case 'Delete':
        await deleteSelectedAttachments(selectedAttachmentIds)
        bulkActionCleanup('Delete')
        break
      default:
        break
    }
  }

  const bulkActionCleanup = (action: AttachmentBulkAction) => {
    setSelectedRowKeys([])
    setSelectedAttachments([])
    refreshUnsentAttachmentGrid(
      facilityId,
      setAttachments,
      getSortedAttachments,
    )
    setGridLoading(false)

    if (action.toString() === 'Send') {
      setGridLoading(true)
      setActiveTab('Sent')
      refreshSentAttachmentGrid(
        facilityId,
        setSentAttachments,
        sentDates,
        getSortedAttachments,
      )
      setGridLoading(false)
    }
  }

  const rowSelection: TableRowSelection<AttachmentListItem> = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows) =>
      onSelectChange(newSelectedRowKeys, selectedRows),
    selections: [
      {
        key: 'send',
        onSelect: () =>
          selectedRowKeys.length > 0 && onSelectBulkAction('Send'),
        text: 'Send',
      },
      {
        key: 'hold',
        onSelect: () =>
          selectedRowKeys.length > 0 && onSelectBulkAction('Hold'),
        text: 'Hold',
      },
      {
        key: 'delete',
        onSelect: () =>
          selectedRowKeys.length > 0 && onSelectBulkAction('Delete'),
        text: 'Delete',
      },
    ],
  }

  const handlePrintTable = useReactToPrint({
    content: () =>
      activeTab === 'Unsent' ? unsentTableRef.current : sentTableRef.current,
  })

  const handleDownloadTable = () => {
    const csvOutput = new ExportTableAsCSV(
      activeTab === 'Unsent' ? unsentTableRef.current : sentTableRef.current,
    ).convertToCSV()
    const csvBlob = new Blob([csvOutput], { type: 'text/csv' })
    const csvBlobUrl = URL.createObjectURL(csvBlob)
    const anchorElement = document.createElement('a')

    anchorElement.href = csvBlobUrl
    anchorElement.download = 'attachments-export.csv'
    anchorElement.click()

    setTimeout(() => {
      URL.revokeObjectURL(csvBlobUrl)
    }, 500)
  }

  return (
    <article>
      <Drawer
        title='Select | Reorder Columns'
        placement='right'
        width={300}
        onClose={() => setShowingSettings(false)}
        visible={showingSettings}
      >
        <ColumnSelection setShowingSelection={setShowingSettings} />
      </Drawer>
      <section className='sa-toolbar'>
        <Tabs
          activeKey={activeTab}
          onChange={(key: string) => onTabChange(key)}
        >
          <TabPane
            key='Unsent'
            tab='Unsent'
            disabled={gridLoading}
          />
          <TabPane
            key='Sent'
            tab='Sent'
            disabled={gridLoading}
          />
        </Tabs>
        <AttachmentsGridToolbar
          downloadTable={handleDownloadTable}
          filters={filters}
          printTable={handlePrintTable}
          sentDates={sentDates}
          searchInput={searchInput}
          setFilters={setFilters}
          setSearchInput={setSearchInput}
        />
      </section>
      {activeTab == 'Unsent' && (
        <Table
          className='sa-grid'
          columns={
            getGridColumns(false, sentDates) as ColumnsType<AttachmentListItem>
          }
          dataSource={data as AttachmentListItem[]}
          loading={gridLoading}
          pagination={pagination}
          rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
          rowKey='attachmentId'
          rowSelection={{ ...rowSelection }}
          size='middle'
          onChange={(
            pagination: TablePaginationConfig,
            filters: Record<string, FilterValue>,
            sorter:
              | SorterResult<AttachmentListItem>
              | SorterResult<AttachmentListItem>[],
            { currentDataSource },
          ) => {
            // Saving the sort order and sort column, to maintain sort when switching tabs
            const sort = sorter as SorterResult<AttachmentListItem>
            sort?.field &&
              setUnsentSortColumn(sort['field'].valueOf() as string)
            sort?.order && setUnsentSortOrder(sort['order'])
          }}
        />
      )}
      {activeTab == 'Sent' && (
        <Table
          className='sa-grid'
          columns={
            getGridColumns(
              false,
              sentDates,
            ) as ColumnsType<SentAttachmentListItem>
          }
          dataSource={data as SentAttachmentListItem[]}
          loading={gridLoading || printingAttachment}
          pagination={pagination}
          rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
          rowKey='hmfId'
          size='middle'
          onChange={(
            pagination: TablePaginationConfig,
            filters: Record<string, FilterValue>,
            sorter:
              | SorterResult<SentAttachmentListItem>
              | SorterResult<SentAttachmentListItem>[],
            { currentDataSource },
          ) => {
            // Saving the sort order and sort column, to maintain sort when switching tabs
            const sort = sorter as SorterResult<AttachmentListItem>
            sort?.field && setSentSortColumn(sort['field'].valueOf() as string)
            sort?.order && setSentSortOrder(sort['order'])
          }}
        />
      )}
      <div style={{ display: 'none' }}>
        {activeTab === 'Unsent' ? (
          <Table
            className='sa-grid'
            columns={
              getGridColumns(true, sentDates) as ColumnsType<AttachmentListItem>
            }
            dataSource={data as AttachmentListItem[]}
            pagination={false}
            ref={unsentTableRef}
            rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
            rowKey='attachmentId'
            rowSelection={null}
            size='middle'
          />
        ) : (
          <Table
            className='sa-grid'
            columns={
              getGridColumns(
                true,
                sentDates,
              ) as ColumnsType<SentAttachmentListItem>
            }
            dataSource={data as SentAttachmentListItem[]}
            pagination={false}
            ref={sentTableRef}
            rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
            rowKey='hmfId'
            rowSelection={null}
            size='middle'
          />
        )}
      </div>
    </article>
  )
}

export default AttachmentsGrid
