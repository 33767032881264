import {
  EyeInvisibleOutlined,
  EyeOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from '@ant-design/icons'
import { Col, Row, Tabs, Tooltip } from 'antd'
import React, { FC, useEffect, useState } from 'react'

import { useClaimDetailContext } from '../../../claims/context/claimDetailContext'
import { ClaimErrorType } from '../../claimTypes'
import { useClaimGridContext } from '../../context/claimGridContext'
import ClaimTab from './_ClaimTab'
import PrintIcon from './_PrintIcon'

import './toolbar.scss'

import { LDFlags$ } from 'trellis:state/globalState'
import { observer } from '@legendapp/state/react'

const { TabPane } = Tabs

type ClaimToolBarType = {
  loading: boolean
}

export const ClaimToolbar: FC<ClaimToolBarType> = observer(({ loading }) => {
  const flags = LDFlags$.get()
  const { state, ignoreClaim, ClaimState$, detailsTab, setDetailsTab } =
    useClaimGridContext()
  const {
    claim,
    fullScreen,
    setFullScreen,
    showClaim,
    hideClaim,
    attachmentErrors,
    isAttachmentRequirementsIgnored,
  } = useClaimDetailContext()

  const patientErrors: ClaimErrorType[] =
    claim &&
    claim?.errors?.filter(
      (error: ClaimErrorType) => error.section?.toLowerCase() === 'patient',
    )
  const proceduresErrors: ClaimErrorType[] =
    claim &&
    claim?.errors?.filter(
      (error: ClaimErrorType) => error.section?.toLowerCase() === 'procedures',
    )
  const providerErrors: ClaimErrorType[] =
    claim &&
    claim?.errors?.filter(
      (error: ClaimErrorType) => error.section?.toLowerCase() === 'provider',
    )
  const eob = claim?.EOB_HTML
  const era = claim?.ERA_HTML
  const [totalErrorsLoaded, setTotalErrorsLoaded] = useState<number>(0)
  const [
    isAttachmentRequirementsIgnoredLoaded,
    setIsAttachmentRequirementsIgnoredLoaded,
  ] = useState<boolean>(null)

  useEffect(() => {
    if (
      isAttachmentRequirementsIgnored !== undefined &&
      isAttachmentRequirementsIgnored !== null
    ) {
      setIsAttachmentRequirementsIgnoredLoaded(true)
    }
  }, [isAttachmentRequirementsIgnored])

  useEffect(() => {
    if (!loading) setErrorTab()
  }, [loading])

  useEffect(() => {
    if (
      isAttachmentRequirementsIgnoredLoaded &&
      !isAttachmentRequirementsIgnored &&
      attachmentErrors > 0 &&
      totalErrorsLoaded < 2
    )
      setErrorTab()
  }, [attachmentErrors, isAttachmentRequirementsIgnoredLoaded])

  const setErrorTab = () => {
    if (ClaimState$.forceShowAttachmentTab.peek()) setDetailsTab('Attachments')
    else if (patientErrors?.length) setDetailsTab('Patient')
    else if (proceduresErrors?.length) setDetailsTab('Procedures')
    else if (providerErrors?.length) setDetailsTab('Provider')
    else if (
      attachmentErrors &&
      isAttachmentRequirementsIgnored !== null &&
      !isAttachmentRequirementsIgnored
    )
      setDetailsTab('Attachments')

    setTotalErrorsLoaded(totalErrorsLoaded + 1)
  }

  return (
    <Row className='toolbar-bottom-border flex items-center'>
      <Col flex={1}>
        <Tabs
          className={fullScreen ? '' : 'mt-150'}
          activeKey={detailsTab}
          onChange={(key: string) => setDetailsTab(key)}
        >
          {!ClaimState$.isNewClaim.peek() && !flags.activityLog && (
            <TabPane
              key='Status'
              tab='Status'
            />
          )}
          <TabPane
            key='Patient'
            tab={
              <ClaimTab
                label='Patient'
                possibleErrors={patientErrors}
              />
            }
          />
          <TabPane
            key='Procedures'
            tab={
              <ClaimTab
                label='Procedures'
                possibleErrors={proceduresErrors}
              />
            }
          />
          <TabPane
            key='Provider'
            tab={
              <ClaimTab
                label='Provider'
                possibleErrors={providerErrors}
              />
            }
          />
          <TabPane
            key='Attachments'
            tab={
              <ClaimTab
                label='Attachments'
                possibleErrors={attachmentErrors}
                isAttachmentRequirementsIgnoredLoaded={
                  isAttachmentRequirementsIgnoredLoaded
                }
                isAttachmentRequirementsIgnored={
                  isAttachmentRequirementsIgnored
                }
              />
            }
          />
          {eob && (
            <TabPane
              key='EOB'
              tab='EOB'
            />
          )}
          {era && (
            <TabPane
              key='ERA'
              tab='ERA'
            />
          )}
          {flags.activityLog && (
            <TabPane
              key='Activity Log'
              tab='Activity Log'
            />
          )}
        </Tabs>
      </Col>
      {state.ActiveTab === 'Sent' && (
        <Col className='grid-control-icon-container mt-100'>
          <Tooltip
            title='Show Claim'
            mouseEnterDelay={0.5}
            className='mt-025'
          >
            {ignoreClaim && (
              <EyeInvisibleOutlined
                onClick={showClaim}
                className='grid-control-icon'
              />
            )}
          </Tooltip>
          <Tooltip
            title='Hide Claim'
            mouseEnterDelay={0.5}
            className='mt-025'
          >
            {!ignoreClaim && (
              <EyeOutlined
                onClick={hideClaim}
                className='grid-control-icon'
              />
            )}
          </Tooltip>
        </Col>
      )}
      {!ClaimState$.isNewClaim.get() && (
        <Col className='grid-control-icon-container mt-100'>
          <PrintIcon />
        </Col>
      )}
      <Col className='grid-control-icon-container mt-100'>
        {claim && fullScreen ? (
          <FullscreenExitOutlined
            className='grid-control-icon'
            onClick={() => setFullScreen(!fullScreen)}
          />
        ) : (
          <FullscreenOutlined
            className='grid-control-icon'
            onClick={() => setFullScreen(true)}
          />
        )}
      </Col>
    </Row>
  )
})
